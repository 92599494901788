export const lang = {
    music: 'Music',
    lang:"LANGUAGE",
    chinese: 'CN',
    engLish: 'EN',
    Japanese:'JP',
    homePage: "HOMEPAGE",
    consultation: 'CONSULTATION',
    brands: "BRANDS",
    fishingRods: "FISHING RODS",
    artificialBaits: "ARTIFICIAL BAITS",
    accessories: 'ACCESSORIES',
    newsCenter: 'NEWS CENTER',
    learnMore: 'Learn More',
    productCategories: 'PRODUCT CATEGORIES',
    companyNews: 'COMPANY NEWS',
    anglerInformation: 'ANGLER INFORMATION',
    fishingRodCategories: 'FISHING ROD CATEGORIES',
    artificialBaitCategories: 'ARTIFICIAL BAIT CATEGORIES',
    accessoryCategorie: 'ACCESSORY CATEGORIES',
    nationalDealers: 'NATIONAL DEALERS',
    placeEnCity: 'Please enter the city or store name for the query',
    enKeyTS: 'Enter Keywords to Search',
    close:"close",
    onlineMap:"ONLINE MAP",
    joinUs:"JOIN US",
    contactus:"Contact Us",
    Address:"Address",
    phoneNumber:"Phone Number",
    email:"Email",
    RAVENCRAFTSignedAnglers:'RAVENCRAFT Signed Anglers',
    RAVENCRAFTAnglerInformation:"RAVENCRAFT Angler Information",
    LearnMore:"Learn More",
    ColorSwatches:"COLOR SWATCHES",
    modelNumber:"MODEL NUMBER",
    specifications:"SPECIFICATIONS",
    CharacteristicIntroduction:"CHARACTERISTIC INTRODUCTION",
    video:"VIDEO"
  }