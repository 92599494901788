export const lang = {
    music: '网易云音乐',
    lang: "语言",
    chinese: 'CN',
    engLish: 'EN',
    Japanese: "JP",
    homePage: "首页",
    consultation: '资讯',
    brands:"品牌",
    fishingRods: "钓竿",
    artificialBaits: "拟饵",
    accessories: '配件',
    newsCenter: '新闻中心',
    learnMore: '了解更多',
    productCategories: '产品分类',
    companyNews: '公司新闻',
    anglerInformation: '钓手信息',
    fishingRodCategories: '钓竿分类',
    artificialBaitCategories: '拟饵分类',
    accessoryCategorie: '配件分类',
    nationalDealers: '全国经销商',
    placeEnCity: '请输入城市或门店名称查询',
    enKeyTS: '输入关键词查询',
    close:"关闭 ",
    onlineMap:"线上地图",
    joinUs:"加入我们",
    contactus:"联系我们",
    Address:"地址",
    phoneNumber:"电话",
    email:"邮箱",
    RAVENCRAFTSignedAnglers:'鸦语签约的钓手',
    RAVENCRAFTAnglerInformation:"关注鸦语签约的钓手",
    LearnMore:"获取更多我们的动态" ,
    ColorSwatches:"色样",
    modelNumber:"型号",
    specifications:"规格",
    CharacteristicIntroduction:"详情介绍",
    video:"视频",
    
}
