import router from 'vue-router'
import Vue from 'vue'

Vue.use(router)

// const VueRouterPush = router.prototype.push
// router.prototype.push = function push(to) {
//     return VueRouterPush.call(this, to).catch(err => err)
// }
// const VueRouterReplace = router.prototype.replace
// router.prototype.replace = function replace(to) {
//     return VueRouterReplace.call(this, to).catch(err => err)
// }
const originalPush = router.prototype.push;
router.prototype.push = function (location) {
    return originalPush.call(this, location).catch(err => { })
};

const routers = new router({
    mode: "hash",
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',

            redirect: { name: 'index' }
        },
        {
            path: '/index',
            component: () => import('@/views/index/index'),
            name: 'index',

        },
        {

            path: '/aboutUs',
            component: () => import('@/views/brand/aboutUs'),
            name: 'aboutUs',

        },
        {

            path: '/companyNews',
            component: () => import('@/views/consult/companyNews'),
            name: 'companyNews',
            meta: {
                keepAlive: true
            }

        },
        {

            path: '/angler',
            component: () => import('@/views/consult/angler'),
            name: 'angler',

        },
        {

            path: '/fishingRod/category',
            component: () => import('@/views/fishingRod/category'),
            name: 'fishCategory',
            meta: {
                keepAlive: true
            }
            

        },
        {

            path: '/fishingRod/goods',
            component: () => import('@/views/fishingRod/goods'),
            name: 'fishGoods',
            meta: {
                keepAlive: true
            }

        },
        {

            path: '/pseudoBait/category',
            component: () => import('@/views/pseudoBait/category'),
            name: 'pseudoBaitCategory',
            meta: {
                keepAlive: true
            }

        },
        {

            path: '/pseudoBait/goods',
            component: () => import('@/views/pseudoBait/goods'),
            name: 'pseudoBaitRodGoods',

        },
        {

            path: '/fittings/category',
            component: () => import('@/views/fittings/category'),
            name: 'fittingsCategory',
            meta: {
                keepAlive: true
            }

        },
        {

            path: '/fittings/goods',
            component: () => import('@/views/fittings/goods'),
            name: 'fittingsGoods',

        },
        {

            path: '/search',
            component: () => import('@/views/search/search'),
            name: 'search',
            meta: {
                keepAlive: true
            }

        },
        {

            path: '/news',
            component: () => import('@/views/consult/news'),
            name: 'news',
            meta: {
                keepAlive: true
            }

        },




    ],

})
//路由守卫判断跳转的是不是home，是的话就判断是否有menu，没有的话就不跳转
routers.beforeEach(async (to, from, next) => {

    console.log(to)
    next()
})
export default routers

