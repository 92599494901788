<template>
    <div>
        <el-carousel trigger="click" :height="height" ref="slideCarousel" v-if="show == 'pc'||!moBSliders">
            <el-carousel-item v-for="(item, index) in sliders" :key="index">
                <img :src="$baseUrl+item.image" alt="" style="width:100%;height:100%;object-fit:cover">
            </el-carousel-item>
        </el-carousel>
        <el-carousel trigger="click" :height="height" ref="slideCarousel" v-if="show == 'mobile'&& moBSliders">
            <el-carousel-item v-for="(item, index) in moBSliders" :key="index">
                <img :src="$baseUrl+item.image" alt="" style="width:100%;height:100%;object-fit:cover">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: "pc"
        }

    },
    props: ["height","sliders","moBSliders"],
    mounted() {
        let that = this
        if(this.moBSliders){
            that.slideBanner()
        }
        if (window.innerWidth > 500) {
            this.show = "pc"

        } else {
            this.show = "mobile"
        }
        window.addEventListener('resize', function () {
            if (window.innerWidth > 500) {
                that.show = "pc"

            } else {
                that.show = "mobile"
            }
        })
    },
    methods: {
        slideBanner() {
            //选中的轮播图
            var box = document.querySelector('.el-carousel__container');
            var startPoint = 0;
            var stopPoint = 0;
            //重置坐标
            var resetPoint = function () {
                startPoint = 0;
                stopPoint = 0;
            }
            //手指按下
            box.addEventListener("touchstart", function (e) {
                //手指点击位置的X坐标
                startPoint = e.changedTouches[0].pageX;
            });
            //手指滑动
            box.addEventListener("touchmove", function (e) {
                //手指滑动后终点位置X的坐标
                stopPoint = e.changedTouches[0].pageX;
            });
            //当手指抬起的时候，判断图片滚动离左右的距离
            let that = this
            box.addEventListener("touchend", function (e) {
                console.log("1：" + startPoint);
                console.log("2：" + stopPoint);
                if (stopPoint == 0 || startPoint - stopPoint == 0) {
                    resetPoint();
                    return;
                }
                if (startPoint - stopPoint > 0) {
                    resetPoint();
                    that.$refs.slideCarousel.next();
                    return;
                }
                if (startPoint - stopPoint < 0) {
                    resetPoint();
                    that.$refs.slideCarousel.prev();
                    return;
                }
            });
        },
    }
}
</script>

<style></style>