<template>
    <div>

        <div class="footer__main">
            <div class="footer__social">
                <div class="container">
                    <div class="footer__social__content">
                        <!-- /* ============ social-list ============ */ -->
                        <ul class="social_list" v-if="icons.length > 0">
                            <li class="social_list__item">
                                <a class="iconfont icon-toutiaoyangshi" :href="icons[0].url || ''" target="_blank"></a>
                            </li>
                            <li class="social_list__item">
                                <a class="iconfont icon-icon_bilibili" :href="icons[1].url || ''" target="_blank"></a>
                            </li>


                            <li class="social_list__item">
                                <div class="iconfont icon-douyin1">
                                    <img :src="$baseUrl + icons[2].qrcode_image" alt="" class="qrcode">
                                </div>

                            </li>
                            <li class="social_list__item">
                                <div class="iconfont icon-weixin1">
                                    <img :src="$baseUrl + icons[3].qrcode_image" alt="" class="qrcode">
                                </div>

                            </li>
                            <li class="social_list__item">
                                <div class="shipinhao">
                                    <img src="../assets/img/logo7.png" alt="" class="shipinhao_o">
                                    <img src="../assets/img/logo7_h.png" alt="" class="shipinhao_h">
                                    <img :src="$baseUrl + icons[4].qrcode_image" alt="" class="qrcode">
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div><!-- //.footer__social -->

            <nav class="footer__menu">
                <div class="container">
                    <ul class="footer__menu__list">
                        <li class="footer__menu__list__item" @click="goPage('index')">
                            <a class="footer__menu__link">
                                {{ $t('lang.homePage') }} </a>
                        </li>
                        <li class="footer__menu__list__item" @click="goPage('companyNews')">
                            <a class="footer__menu__link">
                                {{ $t('lang.consultation') }} </a>
                        </li>
                        <li class="footer__menu__list__item" @click="goPage('fishCategory')">
                            <a class="footer__menu__link">
                                {{ $t('lang.fishingRods') }} </a>
                        </li>
                        <li class="footer__menu__list__item" @click="goPage('pseudoBaitCategory')">
                            <a class="footer__menu__link">
                                {{ $t('lang.artificialBaits') }} </a>
                        </li>
                        <li class="footer__menu__list__item" @click="goPage('fittingsCategory')">
                            <a class="footer__menu__link">
                                {{ $t('lang.accessories') }} </a>
                        </li>
                        <li class="footer__menu__list__item" @click="goPage('aboutUs')">
                            <a class="footer__menu__link">
                                {{ $t('lang.brands') }} </a>
                        </li>
                    </ul>
                    <ul class="footer__menu__list">
                        <li class="footer__menu__list__item" @click="goPage('aboutUs', true)">
                            <a class="footer__menu__link">
                                {{ $t('lang.onlineMap') }}
                            </a>
                        </li>
                        <li class="footer__menu__list__item" @click="goPage('aboutUs')">
                            <a class="footer__menu__link">
                                {{ $t('lang.joinUs') }}
                            </a>
                        </li>

                    </ul>
                </div>
            </nav>

            <div class="footer__copyright">
                <div class="container">
                    <div class="footer__copyright__logo">
                        <img src="../assets/img/logo2_white.png" alt="">
                    </div>
                    <small class="footer__copyright__text">
                        <!-- Copyright © Megabass inc. All Rights Reserved. -->
                        Copyright RAVENCRAFT inc.ICP主体备案号 鲁ICP备19003487号
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            icons: []
        }
    },
    mounted() {
        this.getIcons()
    },
    methods: {
        goPage(name, scoll = false) {
            if (this.$route.name != name) {
                if (scoll) {
                    this.$router.push({
                        name: name,
                        query: {
                            scoll: 1
                        }
                    })
                } else {
                    this.$router.push({
                        name: name
                    })
                }
            }

        },
        async getIcons() {
            let result = await this.$API.getIconList()
            this.icons = result.data
        }
    }
}
</script>

<style scoped>
body {
    margin: 0;
    font-family: Roboto, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, YuGothic, Yu Gothic, æ¸¸ã‚´ã‚·ãƒƒã‚¯ä½“, æ¸¸ã‚´ã‚·ãƒƒã‚¯, Hiragino Kaku Gothic ProN, Hiragino Kaku Gothic Pro, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: left;
    background-color: #fff;
}

.footer__main {
    display: block;
    padding: 30px 0;
    color: #fff;
    background: #3a3a3a;
    background-image: url("../assets/img/footer_bg.jpg");
    background-size: auto 100%;
    background-position: right;
    background-repeat: no-repeat;
}

.footer__social {
    display: block;
    padding: 5px 0;
}

.container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.footer__menu__link {
    color: inherit !important;
}

.footer__menu {
    display: block;
    margin: 0;
    overflow: hidden;
    font-size: 13.5px;
    padding: 25px 0;
}

.social_list {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: auto;
    margin: 0 -15px;
    padding: 0;
}

.social_list__item {
    display: block;
    padding: 0 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

@media screen and (min-width: 567.2px) {
    .footer__menu {
        padding: 50px 0 !important;
    }

    .footer__menu__list__item+.footer__menu__list__item {
        border-left: 1px solid;
        margin-top: 0;
    }

    .footer__menu__list+.footer__menu__list {
        margin-top: 30px;
    }
}

.footer__menu {
    display: block;
    margin: 0;
    overflow: hidden;
    font-size: 13.5px;
    padding: 25px 0;
}

@media screen and (min-width: 567.2px) {
    .footer__menu__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media screen and (max-width: 400.2px) {
    .footer__menu__list {
        padding-left: 0;
        list-style: none;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .footer__menu__list__item {
        display: block;
        padding: 0 30px;
        width: 30%;
    }
}



.footer__menu__list {
    padding-left: 0;
    list-style: none;
    width: auto;
}

.footer__menu__list__item {
    display: block;
    padding: 0 30px;
}

.footer__copyright {
    display: block;
    padding: 10px 0;
}

.footer__copyright__logo {
    width: 100%;
    max-width: 214px;
    margin: 0 auto 15px;
}

.footer__copyright__logo img {
    display: block;
    width: 100%;
    height: auto;
}

.footer__copyright__text {
    display: block;
    text-align: center;
    font-size: 9px;
}

.icon-weixin1,
.icon-toutiaoyangshi,
.icon-icon_bilibili,
.icon-douyin1 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.icon-icon_bilibili {
    font-size: 45px;
}

.icon-weixin1 {
    font-size: 30px;
    position: relative;
}

.icon-icon_bilibili:hover {
    background-color: #fff;
    color: #63a4ce;
    border-radius: 50%;
}

.icon-toutiaoyangshi:hover {
    background-color: #fff;
    color: red;
    border-radius: 50%;
}

.icon-douyin1 {
    position: relative;
}

.icon-douyin1:hover {
    background-color: #000;
    border-radius: 50%;
}

.icon-douyin1:hover .qrcode {
    opacity: 1;
    display: block;
    max-width: 200px;
}

.icon-weixin1:hover {
    background-color: #51b76b;
    border-radius: 50%;
}

.icon-weixin1:hover .qrcode {
    opacity: 1;
    display: block;
    max-width: 200px;
}

.shipinhao {
    position: relative;
    width: 40px;
    height: 40px;
}

.shipinhao_o,
.shipinhao_h {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    cursor: pointer;
    transition: all 0.2s ease;
}

.shipinhao:hover .shipinhao_h {
    opacity: 1;
}

.shipinhao:hover .qrcode {
    opacity: 1;
    display: block;
    max-width: 200px;
}

.shipinhao_o {

    width: auto;
    height: 20px;
}

.shipinhao_h {
    width: auto;
    height: 40px;
    opacity: 0;
}

.qrcode {
    position: absolute;
    bottom: 110%;
    right: 50%;
    max-width: 0px;
    height: auto;

    transform: translateX(50%);
    opacity: 0;
    transition: all 0.2s ease;

}

@media screen and (max-width: 500px) {
    .shipinhao .qrcode {
        right: 0;
        transform: translateX(0);
    }

    .shipinhao:hover .qrcode {
        opacity: 1;
        display: block;
        max-width: 200px;
    }
}
</style>