<template>
  <div>
    <div class="nav_box " :class="bgShow || type == 'goods' || type == 'search' ? 'bg_wit' : 'bg_tra'"
      v-if="show == 'pc'">
      <div class="nav">
        <div class="logo dis_c">
          <img src="../assets/img/logo2_black.png" alt="" class="logoImg"
            :class="bgShow || type == 'goods' || type == 'search' ? 'logo_show' : ''">
          <img src="../assets/img/logo2_white.png" alt="" class="logoImg" :class="bgShow ? '' : 'logo_show'">
        </div>
        <div class=" nav_ul">
          <ul class="dis_b_c" style="flex-wrap:wrap">
            <li :class="{ active: index == 1 }" @click="goPage('index')">{{ $t('lang.homePage') }}</li>
            <li :class="{ active: index == 2 }" @click="goPage('companyNews')">{{ $t('lang.consultation') }}</li>

            <li :class="{ active: index == 4 }" @click="goPage('fishCategory')">{{ $t('lang.fishingRods') }}</li>
            <li :class="{ active: index == 5 }" @click="goPage('pseudoBaitCategory')">{{ $t('lang.artificialBaits') }}</li>
            <li :class="{ active: index == 6 }" @click="goPage('fittingsCategory')">{{ $t('lang.accessories') }}</li>
            <li :class="{ active: index == 3 }" @click="goPage('aboutUs')">{{ $t('lang.brands') }}</li>

          </ul>
          <div class="nav_hover" :style="'width:min(300px,' + navHoverWidth + 'px;left:' + navHoverLeft + 'px'"></div>
        </div>




      </div>
      <div class="dis_c">
        <div class="iconfont icon-sousuoxiao  dis_c_c nav_b " :class="{ active: type == 'search' }">
          <div class="search dis_c_c">
            <div class="search_box dis_b_c">
              <input type="text" v-model="keyword" :placeholder="$t('lang.enKeyTS')">
              <div class="iconfont icon-sousuoxiao" @click="toSearch">

              </div>
            </div>
          </div>
        </div>

        <div class="iconfont icon-caidan dis_c_c nav_b">
          <div class="language">
            <div @click="changelan('zh')">
              {{ $t('lang.chinese') }}
            </div>
            <div @click="changelan('en')">
              {{ $t('lang.engLish') }}
            </div>
            <div @click="changelan('jp')">
              {{ $t('lang.Japanese') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show == 'mobile'">
      <div class="mob_nav dis_b_c" :class="bgShow || type == 'goods' || type == 'search' ? 'bg_wit' : 'bg_tra'">
        <div class="logo dis_c">
          <img src="../assets/img/logo2_black.png" alt="" v-if="bgShow || type == 'goods' || type == 'search'">
          <img src="../assets/img/logo2_white.png" alt="" v-if="!bgShow">
        </div>
        <div class="icon-caidan iconfont" @click="menu = true">

        </div>
      </div>
      <div class="menu_box" :style="menu ? 'height:100vh;padding:20px;' : 'height:0vh;padding:0;'">
        <div class="close" @click="menu = false">
          {{$t('lang.close')}}
        </div>
        <div class="mob_search dis_b_c">

          <div class="mob_input">
            <input type="text" v-model="keyword">
          </div>

          <div class="iconfont icon-sousuoxiao" @click="toSearch"></div>
        </div>
        <h3>PRODUCTS</h3>
        <ul>

          <li @click="goPage('index')">{{ $t('lang.homePage') }}</li>
          <li @click="goPage('companyNews')">{{ $t('lang.consultation') }}</li>

          <li @click="goPage('fishCategory')">{{ $t('lang.fishingRods') }}</li>
          <li @click="goPage('pseudoBaitCategory')">{{ $t('lang.artificialBaits') }}</li>
          <li @click="goPage('fittingsCategory')">{{ $t('lang.accessories') }}</li>
          <li @click="goPage('aboutUs')">{{ $t('lang.brands') }}</li>
          <li @click="showLen = !showLen">
            <div>{{ $t('lang.lang') }}</div>
            <div>{{ showLen ? '-' : '+' }}</div>

          </li>
          <div class="lan" :style="showLen ? ('height:120px') : 'height:0px;'">
            <ul ref="menu">
              <li @click="changelan('zh')">{{ $t('lang.chinese') }}</li>
              <li @click="changelan('en')">{{ $t('lang.engLish') }}</li>
              <li @click="changelan('jp')">{{ $t('lang.Japanese') }}</li>
            </ul>
          </div>
        </ul>
        <div class="close" @click="menu = false">
          {{$t('lang.close')}}
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: 'navTop',

  data() {
    return {
      keyword: "",//搜索关键词
      bgShow: false,//判断菜单栏背景是否为白色
      show: "pc",//判断当前都是移动端还是pc端
      showLen: false,//移动端是否显示语言
      menu: false,//移动端是否显示菜单栏
      navHoverWidth: "",//选中的nav的width
      navHoverLeft: "",//选中的nav的left
      navBoxLeft: 0,//nav的left
    }
  },
  props: ["index", "type"],
  mounted() {

    let that = this

    // 监听滚动
    window.addEventListener('scroll', function (e) {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 100) {
        that.changeBg(true)
      } else {
        that.changeBg(false)
      }
    });
    if (window.innerWidth > 700) {
      that.show = "pc"

    } else {
      that.show = "mobile"

    }
    //console.log(that.show, window.innerWidth)


    window.addEventListener("resize", function () {
      if (window.innerWidth > 700) {
        that.show = "pc"

      } else {
        that.show = "mobile"
      }

    })
    this.$nextTick(() => {
      this.navBoxLeft = document.querySelector(".nav_ul").offsetLeft
      this.navHoverLeft = document.querySelector(".active").offsetLeft
      this.navHoverWidth = document.querySelector(".active").offsetWidth
    })

    //console.log(this.navBoxLeft, this.navHoverLeft, this.navHoverWidth)

    let lis = document.querySelectorAll(".nav_ul li")
    lis.forEach(item => {
      item.addEventListener("mouseover", function () {
        that.navHoverLeft = item.offsetLeft
        that.navHoverWidth = item.offsetWidth
      })
      item.addEventListener("mouseleave", function () {
        that.navHoverLeft = document.querySelector(".active").offsetLeft
        that.navHoverWidth = document.querySelector(".active").offsetWidth
      })
    })

  },
  methods: {
    changelan(la) {

      switch (la) {
        case "zh":
          // this.$i18n.locale = 'zh-CN'
          // Cookies.set("think_var", "zh-cn", {})
          localStorage.setItem("zh", "zh-CN")
          break;
        case "en":
          // this.$i18n.locale = 'en-US'
          // Cookies.set("think_var", "zh-en", {})
          localStorage.setItem("zh", "en-US")
          break;
        case "jp":
          // this.$i18n.locale = 'jp-JP'
          // Cookies.set("think_var", "zh-jp", {})
          localStorage.setItem("zh", "jp-JP")
          break;

      }
      window.location.reload()
    },
    toSearch() {
      //console.log(123)
      this.$router.push({
        path: "/search",
        query: {
          keyword: this.keyword
        }
      })
      this.menu = false
      localStorage.setItem("keyword", this.keyword)
      this.$bus.$emit("search")
    },
    changeBg(flag) {
      this.bgShow = flag
    },
    goPage(name) {
      if (this.$route.name != name) {
        this.$router.push({
          name: name
        })
      }


      this.menu = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("@/assets/css/flex.css");

.nav_box {
  display: flex;
  justify-content: flex-end;
  padding: 0 50px;
  box-sizing: border-box;
}

.nav_box ul {
  list-style-type: none;
  padding: 0;
  font-weight: 600;
  height: 100%;
}

.nav_box li {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
}

/*

.nav_box li:hover {
  position: relative;

}

.nav_box li:hover::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: red;
  position: absolute;
  bottom: 0;
  left: 0;
}
*/

.nav_b:hover::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: red;
  position: absolute;
  bottom: 0;
  left: 0;
}



a {
  color: #42b983;
}

.nav {
  width: auto;
  height: 100%;
}

.logoImg {
  display: none;
  width: 200px;

}

.logo_show {
  display: block;
}

.nav_box {
  width: 100vw;


  height: 60px;

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
}

.nav_box:hover {
  background-color: #fff !important;
  color: #000 !important;

}

.nav_box:hover .logoImg:first-child {
  display: block !important;
}

.nav_box:hover .logoImg:last-child {
  display: none !important;
}

.bg_tra {
  background-color: transparent;
  color: #fff;
}

.bg_wit {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0px 3px 3px rgba(102, 102, 102, 0.5);
}

/*
.active {
  position: relative;
}

.active::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: red;
  position: absolute;
  bottom: 0;
  left: 0;
}
*/

.nav_b {
  position: relative;

  height: 100%;
  font-size: 20px;
  cursor: pointer;
  width: 50px;
  margin: 0 20px;
}

.nav>.icon-sousuoxiao {
  font-size: 18px;
}

.search {
  position: fixed;
  top: 60px;
  left: 0;
  background-color: #3a3a3a;
  width: 100%;
  height: 0px;
  display: flex;
  transition: all 0.5s ease;
  overflow: hidden;
}

.icon-sousuoxiao:hover .search {
  display: flex;
  height: 200px;

}

.nav_b:first-child {
  margin-left: 100px;
}




.search_box {
  width: 1000px;
  border-bottom: solid 1px #ccc;
  padding: 10px 0;
}

.search_box input {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
  flex: 1;
}

.search_box .icon-sousuoxiao {
  position: relative;
  color: #fff;
  font-size: 20px;
}

.lan {
  overflow: hidden;
  transition: all 0.2s ease;
}

.lan li {
  height: 40px;
  line-height: 40px;
  padding: 0 10px !important;
  box-sizing: border-box;
}



.language {
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  width: 100px;
  height: 0px;
  background-color: #fff;

  border-radius: 5px;
  display: block;
  overflow: hidden;
  color: #000;
  transition: all 0.5s ease;
}

.icon-caidan:hover .language {
  display: block;
  height: 120px;
  box-shadow: 0 2px 2px 2px #ccc;

}

.language div {
  font-size: 16px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;

}

.logo {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 20px;
  padding: 5px;
  box-sizing: border-box;
}

.logo img {
  width: auto;
  height: 40%;
  object-fit: scale-down;
}

.mob_nav {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.mob_nav .icon-caidan {
  font-size: 35px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  height: 100%;
  line-height: 50px;


}

.menu_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  overflow: hidden;
}

.mob_search input {
  padding: 5px;
  box-sizing: border-box;
  flex: 1;
}

.mob_search .icon-sousuoxiao {
  font-size: 20px;
  margin-left: 10px;
}

.close {
  font-size: 14px;
  text-align: end;
  margin-bottom: 10px;
  text-decoration: solid;
  cursor: pointer;
  padding: 20px 0;

}

.menu_box h3 {
  border-bottom: solid 1px #000;
  padding: 30px 0 10px;
}

.menu_box li {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: solid 1px #000;
  display: flex;
  justify-content: space-between;
}

.menu_box .len {
  width: 100%;
}

.menu_box .len li {
  width: 100%;
}

.mob_input {
  border: solid 1px #3a3a3a;
  flex: 1;
  padding: 2px 5px;
  box-sizing: border-box;
}

.mob_input input {
  width: 100%;
}

.nav_ul {
  position: relative;
  height: 100%;
}

.nav_hover {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: red;
  transition: all 0.3s ease;
}
</style>
