<template>
    <div class="total">
        <ul class="dis" :style="'transform: translateX(' + left + 'px);'" :class="showtr ? 'tran' : ''"
            @mousedown="starmove" @mouseleave="stopmove" @touchstart="touchstarmove" @touchmove="touchmoveing"
            @touchend="touchLink" @mousemove="moveing" @mouseup="link">
            <li class="card" v-for="item, index in videos" :key="index" @click="tiao(item)"
                :style="'min-width: calc((100vw - 20px) / ' + col + ');' + 'max-width: calc((100% - 0px) / ' + col + ');' + 'min-width: calc((100% - 0px) / ' + col + ');'">
                <img :src="$baseUrl + item.image" alt="" :style="'height:' + height + 'px'">
                <h3 class="title">{{ item.name }}</h3>
                <p class="line_3">

                    {{ item.title }}
                </p>
            </li>

        </ul>
        <ul class="slick-dots dis_c_c" v-if="videos.length > col">
            <li class="slick-dot" :class="{ active: index == i }" v-for=" (item, i) in videos.length - col + 1" :key="i"
                @click.stop="changeIndex(i)">
            </li>
        </ul>
        <div style="both:clear;"></div>
    </div>
</template>
  
<script>
export default {
    name: "Dashboard",
    data() {
        return {
            len: 10,
            move: false,
            startX: 0,
            left: 0,
            startLeft: 0,
            index: 0,
            showtr: false,
            col: 6,
            height: 0,
            isLink: true,
            type: 0,
            id: 0
        };
    },
    created() {
    },
    props: {
        videos: {
            type: Array,
            default: []

        }
    },
    mounted() {
        let that = this
        console.log(this.videos)
        if (window.innerWidth > 1600) {
            that.col = 6
        }
        if (window.innerWidth > 1200 && window.innerWidth < 1600) {
            that.col = 5
        }
        if (window.innerWidth > 920 && window.innerWidth < 1200) {
            that.col = 4
        }
        if (500 < window.innerWidth && window.innerWidth < 920) {
            that.col = 3
        }
        if (window.innerWidth < 500) {
            that.col = 2
        }
        that.$nextTick(() => {
            that.height = document.querySelectorAll(".card")[0].offsetWidth * (2 / 3)
        });
        this.height = document.querySelectorAll(".card")[0].offsetWidth * (2 / 3)
        window.addEventListener("resize", this.resize)

    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resize)
    },
    methods: {
        tiao(item) {
            console.log(item)
            this.type = item.product_type
            if (this.type == 1) {
                this.id = item.id
            }
            if (this.type == 2 || this.type == 2) {
                this.id = item.spec_id
            }
        },
        stop() {

        },
        toNews() {
            //console.log("1323")
            let type = this.type
            let id = this.id

            if (type == 4 || type == 5) {
                this.$router.push({
                    name: "news",
                    query: {
                        type: type,
                        id: id
                    }
                })
            }
            if (type == 2 || type == 3) {
                this.$router.push({
                    name: "pseudoBaitRodGoods",
                    query: {
                        type: type,
                        id: id
                    }
                })
            }
            if (type == 1) {
                this.$router.push({
                    name: "fishGoods",
                    query: {
                        id: id
                    }
                })
            }

        },
        //移动
        resize() {
            let that = this
            if (window.innerWidth > 1600) {
                that.col = 6
            }
            if (window.innerWidth > 1200 && window.innerWidth < 1600) {
                that.col = 5
            }
            if (window.innerWidth > 920 && window.innerWidth < 1200) {
                that.col = 4
            }
            if (500 < window.innerWidth && window.innerWidth < 920) {
                that.col = 3
            }
            if (window.innerWidth < 500) {
                that.col = 2
            }
            //console.log("resize")
            that.$nextTick(() => {
                that.height = document.querySelectorAll(".card")[0].offsetWidth * (2 / 3)
            });
        },
        //开始移动
        starmove(e) {

            this.move = true
            this.startX = e.pageX
            this.startLeft = this.left
            this.showtr = false
            this.isLink = true
        },
        touchstarmove(e) {
            //console.log(e)
            this.move = true
            this.startX = e.changedTouches[0].pageX
            this.startLeft = this.left
            this.showtr = false
            this.isLink = true
        },
        link(e) {
            //console.log(this.isLink, 'link')
            if (this.isLink) {
                this.toNews()

            } else {
                this.stopmove(e)
            }
            this.isLink = true

        },
        //鼠标悬停时停止移动
        stopmove(e) {

            let width = document.querySelectorAll(".card")[0].offsetWidth
            let moveWeight = width * 2 / 3
            let change = e.pageX - this.startX
            if (this.move) {

                if (change < -width || change < -moveWeight) {
                    let newInd = this.index + 1
                    let max = this.videos.length - this.col
                    if (newInd >= max) {
                        this.index = max >= 0 ? max : 0
                    } else {
                        this.index = newInd
                    }

                }
                if (change > width || change > moveWeight) {
                    let newInd = this.index - 1
                    if (newInd <= 0) {
                        this.index = 0
                    } else {
                        this.index = newInd
                    }

                }
            }
            this.move = false

            this.changeIndex(this.index)
        },
        touchLink(e) {
            //console.log(this.isLink, 'link')
            if (this.isLink) {
                this.toNews()

            } else {
                this.touchstopmove(e)
            }
            this.isLink = true
        },
        //手指停止移动
        touchstopmove(e) {

            let width = document.querySelectorAll(".card")[0].offsetWidth
            let moveWeight = width * 2 / 3
            let change = e.changedTouches[0].pageX - this.startX
            if (this.move) {

                if (change < -width || change < -moveWeight) {
                    let newInd = this.index + 1
                    let max = this.videos.length - this.col
                    if (newInd >= max) {
                        this.index = max >= 0 ? max : 0
                    } else {
                        this.index = newInd
                    }

                }
                if (change > width || change > moveWeight) {
                    let newInd = this.index - 1
                    if (newInd <= 0) {
                        this.index = 0
                    } else {
                        this.index = newInd
                    }

                }
            }
            this.move = false

            this.changeIndex(this.index)
        },
        moveing(e) {
            this.isLink = false
            if (this.move) {
                this.left = this.startLeft + (e.pageX - this.startX)
            }
        },
        touchmoveing(e) {
            this.isLink = false
            if (this.move) {
                this.left = this.startLeft + (e.changedTouches[0].pageX - this.startX)
            }
        },
        changeIndex(ind) {
            this.showtr = true
            this.index = ind
            let width = document.querySelectorAll(".card")[0].offsetWidth
            this.left = -width * ind

        }

    },
};
</script>
  
<style lang="less" scoped>
.total {
    width: 100%;
    position: relative;
    overflow: hidden;

}

.total ul:first-child {}

.tran {
    transition: all 0.5s ease;
}

.card {

    list-style: none;
    text-align: start;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
}

.card .title {
    height: 50px;
    user-select: none;
}

.card img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    user-select: none;

}

.card p {
    font-size: 12px;
    color: #292828;
    line-height: 1.5;
    user-select: none;
}

.slick-dots {
    margin-top: 40px;
}

.slick-dot {
    width: 30px;
    height: 4px;
    background-color: #fff;
    margin: 0 2px;
    cursor: pointer;
}

.active {
    background-color: #000000;
}
</style>