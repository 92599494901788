export const lang = {
    music: '网易云音乐',
    lang: "言語",
    chinese: 'CN',
    engLish: 'EN',
    Japanese: 'JP',
    homePage: "ホームページ",
    consultation: 'お問い合わせ',
    brands: "ブランド",
    fishingRods: "釣り竿",
    artificialBaits: "エサ",
    accessories: 'アクセサリー',
    newsCenter: 'ニュースセンター',
    learnMore: '詳しく',
    productCategories: '製品カテゴリ',
    companyNews: 'ニュース',
    anglerInformation: '釣り手の情報',
    fishingRodCategories: '釣り竿の分類',
    artificialBaitCategories: 'エサの分類',
    accessoryCategorie: 'アクセサリーの分類',
    nationalDealers: '全国の販売代理店',
    placeEnCity: '店舗名を入力して検索してくださ',
    enKeyTS: 'キーワードを入力して検索してください',
    close: "閉鎖し",
    onlineMap: "オンラインマップ",
    joinUs: "お申し込み",
    contactus: "連絡してきます ",
    Address: "住所",
    phoneNumber: "携帯電話番号",
    email: "メールアドレス",
    RAVENCRAFTSignedAnglers: ' 契約の釣り手 ',
    RAVENCRAFTAnglerInformation: "釣り手の情報",
    LearnMore: "詳しく",
    ColorSwatches:"色",
    modelNumber:"型番",
    specifications:"仕様",
    CharacteristicIntroduction:"特徴紹介",
    video:"ビデオ"
}
