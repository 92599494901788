//对于axios进行二次封装
import axios from "axios";
//底下的代码也是创建axios实例
let requests = axios.create({
    //基础路径
    baseURL: "",
    // https://zhuan.igetv.com/nMdupLJkZw.php
    //请求不能超过5S
    timeout: 5000,
});

//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
requests.interceptors.request.use((config) => {

  
    //需要携带token带给服务器
    config.headers.token = "93971dc2-5bf2-41d6-91b1-4c3a0792312d"

    config.headers["x-requested-with"] = "XMLHttpRequest"

    return config;

});

//响应拦截器----当服务器手动请求之后，做出响应（相应成功）会执行的
requests.interceptors.response.use(
    (res) => {
        //相应成功做的事情
 
        if(res.data.msg=="请登录后操作"){
            window.location.href="https://zhuan.igetv.com/nMdupLJkZw.php/index/logout"
        }else{
            return res.data;
        }
        
    },
    (err) => {
        // alert("服务器响应数据失败");
    }
);
export default requests;
