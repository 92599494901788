<template>
    <div class="total_box" @mouseup="stopmove" @touchmove="moveing">
        <div class="total" @mousedown="starmove" @mouseleave="stopmove" @touchstart="touchstarmove"
            @touchmove="touchmoveing" @touchend="touchLink" @mousemove="moveing" @mouseup="link">
            <ul class="dis_c" :style="'transform: translateX(' + left + 'px);'" :class="showtr ? 'tran' : ''">
                <li class="card" v-for="item, index in videos" :key="index"
                    :style="'width: calc((100% - 0px) / ' + col + ');' + 'max-width: calc((100% - 0px) / ' + col + ');' + 'min-width: calc((100% - 0px) / ' + col + ');'">
                    <div style="width:100%;overflow:hidden;" :style="'height:' + height + 'px'">
                        <img :src="$baseUrl + item" alt="" :style="'height:' + height + 'px'" class="card_img">
                    </div>
                    <div>

                    </div>

                </li>

            </ul>


            <div style="both:clear;"></div>
        </div>



        <div class="poxBtn" data-v-8a16fc4a="">
            <div class="pox-prev" data-v-8a16fc4a="" tabindex="0" role="button" aria-label="Previous slide"
                aria-disabled="false" @click="arrowLeft" v-if="index > 0"></div>
            <div class="pox-next " data-v-8a16fc4a="" tabindex="0" role="button" aria-label="Next slide"
                aria-disabled="false" @click="arrowRight" v-if="index < videos.length - col"></div>
        </div>


    </div>
</template>
  
<script>
export default {
    name: "Dashboard",
    data() {
        return {
            len: 10,
            move: false,
            startX: 0,
            left: 0,
            startLeft: 0,
            index: 0,
            showtr: false,
            col: 3,
            height: 200,
            isLink: true
        };
    },
    created() {
    },
    props: ["videos", "id"],
    mounted() {
        let that = this
        if (window.innerWidth > 500) {
            this.col = 3
        } else {
            this.col = 2
        }
        that.$nextTick(() => {
            that.height = document.querySelectorAll(".card")[0].offsetWidth * (4 / 3)
        })

        window.addEventListener("resize", this.resize)

    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resize)
    },
    methods: {
        toNews() {
            this.$router.push({
                name: "news",
                query: {
                    id: this.id,
                    type:4
                }
            })

        },
        //移动  
        resize() {
            let that = this

            if (window.innerWidth > 500) {
                that.col = 3
            } else {
                that.col = 2
            }
            that.$nextTick(() => {
                that.height = document.querySelectorAll(".card")[0].offsetWidth * (4 / 3)
            })

        },
        //开始移动
        starmove(e) {
            this.move = true
            this.startX = e.pageX
            this.startLeft = this.left
            this.showtr = false
            this.isLink = true
        },
        touchstarmove(e) {
            //console.log(e)
            this.move = true
            this.startX = e.changedTouches[0].pageX
            this.startLeft = this.left
            this.showtr = false
            this.isLink = true
        },
        link(e) {
            //console.log(this.isLink, 'link')
            if (this.isLink) {
                this.toNews()

            } else {
                this.stopmove(e)
            }
            this.isLink = true

        },
        //鼠标悬停时停止移动
        stopmove(e) {
            //console.log("moveStop", this.col, this.index, this.isLink)
            let width = document.querySelectorAll(".card")[0].offsetWidth
            let change = e.pageX - this.startX
            if (this.move) {
                if (change < -width || change < -500) {
                    let newInd = this.index + 1
                    let max = this.videos.length - this.col
                    if (newInd >= max) {
                        this.index = max >= 0 ? max : 0
                    } else {
                        this.index = newInd
                    }

                }
                if (change > width || change > 500) {
                    let newInd = this.index - 1
                    if (newInd <= 0) {
                        this.index = 0
                    } else {
                        this.index = newInd
                    }

                }
            }
            this.move = false

            this.changeIndex(this.index)
        },
        touchLink(e) {
            //console.log(this.isLink, 'link')
            if (this.isLink) {
                this.toNews()

            } else {
                this.touchstopmove(e)
            }
            this.isLink = true
        },
        //手指停止移动
        touchstopmove(e) {
            //console.log("moveStop", this.col, this.index)
            let width = document.querySelectorAll(".card")[0].offsetWidth
            let moveWeight = width * 2 / 3
            let change = e.changedTouches[0].pageX - this.startX
            if (this.move) {

                if (change < -width || change < -moveWeight) {
                    let newInd = this.index + 1
                    let max = this.videos.length - this.col
                    if (newInd >= max) {
                        this.index = max >= 0 ? max : 0
                    } else {
                        this.index = newInd
                    }


                }
                if (change > width || change > moveWeight) {
                    let newInd = this.index - 1
                    if (newInd <= 0) {
                        this.index = 0
                    } else {
                        this.index = newInd
                    }

                }
            }
            this.move = false

            this.changeIndex(this.index)
        },
        arrowLeft() {
            let newInd = this.index - 1
            if (newInd < 0) {
                this.index = 0
            } else {
                this.index = newInd
            }
            this.changeIndex(this.index)
        },
        arrowRight() {
            let newInd = this.index + 1
            let max = this.videos.length - this.col
            if (newInd >= max) {
                this.index = max >= 0 ? max : 0
            } else {
                this.index = newInd
            }
            this.changeIndex(this.index)
        },

        moveing(e) {
            this.isLink = false
            //console.log("move")
            if (this.move) {
                this.left = this.startLeft + (e.pageX - this.startX)
            }
        },
        touchmoveing(e) {
            this.isLink = false
            if (this.move) {
                this.left = this.startLeft + (e.changedTouches[0].pageX - this.startX)
            }
        },
        changeIndex(ind) {
            this.showtr = true
            this.index = ind
            let width = document.querySelectorAll(".card")[0].offsetWidth
            this.left = -width * ind


        }

    },
};
</script>
  
<style lang="less" scoped>
.total {
    width: 100%;

    overflow: hidden;

}

.total_box {
    position: relative;
}

/*

.arrows_box_left {
    width: 150px;
    height: 100%;
    position: absolute;
    left: -100px;
    top: 0;

}


.arrow_left {
    position: absolute;
    bottom: 20%;
    left: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #b2b1b1;
    font-size: 40px;
    margin-right: 20px;
    opacity: 0;
    cursor: pointer;
}

.arrows_box_left:hover .arrow_left {
    opacity: 1;
    animation: jiantou_left 0.5s ease;
}

.arrows_box_right {
    width: 150px;
    height: 100%;
    position: absolute;
    right: -100px;
    top: 0;

}


.arrow_right {
    position: absolute;
    bottom: 20%;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #b2b1b1;
    font-size: 40px;
    margin-right: 20px;
    opacity: 0;
    cursor: pointer;
}

.arrows_box_right:hover .arrow_right {
    opacity: 1;
    animation: jiantou 0.5s ease;
}
*/

.arrow_right {
    position: absolute;
    bottom: 20%;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #b2b1b1;
    font-size: 40px;
    cursor: pointer;
}

.arrow_right {
    position: absolute;
    bottom: 20%;
    left: 100%;
    width: 60px;
    height: 30px;
    cursor: pointer;

}

.arrow_right::after {
    content: "";
    width: 50px;
    height: 100%;
    background: url(../assets/img/next.png) no-repeat 0;
    background-size: 50px auto;
    position: absolute;
    right: 14px;
    top: 0;
    transition: all .3s;
}

@keyframes jiantou_left {
    0% {
        opacity: 0;
        left: 130px;

    }

    100% {
        opacity: 1;
        left: 50px;
    }
}

@keyframes jiantou {
    0% {
        opacity: 0;
        right: 100px;

    }

    100% {
        opacity: 1;
        right: 20px;
    }
}

.total ul:first-child {}

.tran {
    transition: all 0.5s ease;
}

.card {

    list-style: none;
    text-align: start;
    padding-left: 10px;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    overflow: hidden;

}


.card .title {
    height: 50px;
    user-select: none;
}

.card img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    user-select: none;
    display: block;
}

.card p {
    font-size: 12px;
    color: #292828;
    line-height: 1.5;
    user-select: none;
}

.slick-dots {
    margin-top: 40px;
}

.slick-dot {
    width: 30px;
    height: 4px;
    background-color: #fff;
    margin: 0 2px;
    cursor: pointer;
}

.active {
    background-color: #000000;
}

.poxBtn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-30px);
    z-index: 100;
}

.poxBtn .pox-prev {
    float: left;
    width: 80px;
    height: 60px;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
}

.pox-prev:before {
    content: "";
    width: 20px;
    height: 100%;
    background: url(../assets/img/prev.png) no-repeat 0;
    position: absolute;
    right: 14px;
    top: 0;
    transition: all .3s;
}

.pox-next {
    float: left;
    width: 80px;
    height: 60px;
    cursor: pointer;
    outline: none;
    position: absolute;
    left: 0;
    top: 0;
}

.card_img {
    transition: transform 1s;
}

.card_img:hover {
    transform: scale(1.05);

}

.pox-next:before {
    content: "";
    width: 66px;
    height: 100%;
    background: url(../assets/img/next.png) no-repeat 100%;
    position: absolute;
    left: 14px;
    top: 0;
    transition: all .3s;
}

.poxBtn .pox-prev:hover::before {
    width: 66px;
}

.poxBtn .pox-prev:hover+.pox-next::before {
    width: 20px;
}

@media screen and (max-width: 500px) {
    .poxBtn {
        right: 40px;
        transform: scale(0.5);
    }

}
</style>