<template>
  <div id="app">
    <!-- <div style="height:60px"></div> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: 'App',
  data() {
    return {
        appShow:'pc'
    }
  },
  mounted() {
    // if(Cookies.get){
    //   Cookies.set("think_var", "zh-cn", {});
    // }
    let that=this
    if (window.innerWidth > 500) {
      this.appShow = "pc"

    } else {
      this.appShow = "mobile"
    }
    window.addEventListener('resize', function () {
      console.log(that.appShow)
      if (window.innerWidth > 500) {
        that.appShow = "pc"

      } else {
        that.appShow = "mobile"
      }
    })
  }
}
</script>

<style>
@import url("@/assets/css/flex.css");
@import url("@/assets/css/iconfont.css");
@import url("@/assets/css/color.css");
@import url("@/assets/css/style3.css");
@import url("@/assets/css/aboutUs.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

*,
body {
  padding: 0;
  margin: 0;

  -webkit-user-drag: none;
  -webkit-user-select: none;

}

body {
  margin: 0;
  font-family: Roboto, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, YuGothic, Yu Gothic, æ¸¸ã‚´ã‚·ãƒƒã‚¯ä½“, æ¸¸ã‚´ã‚·ãƒƒã‚¯, Hiragino Kaku Gothic ProN, Hiragino Kaku Gothic Pro, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

table,
th,
td {
  border: 1px solid black;
  border-spacing: 0px;
  border-color: #aaa;
  empty-cells: show;
}

.spec_title {
  background-color: #d9d9d9 !important;
  text-align: center !important;
  border-top: solid 1px #aaa;
  border-bottom: solid 1px #aaa;
  border-left: solid 1px #aaa;
  color: #3a3a3a;

}

.spec_li {
  text-align: center !important;
  border-bottom: solid 1px #aaa !important;
  border-left: solid 1px #aaa !important;

}

.page_series__section__title {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 10px;
}

.page_products__summary p {
  font-size: 14px;
  line-height: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 14px;
}

.icon-sousuoxiao {
  cursor: pointer;
}

input {
  border: none;
  outline: none;
}</style>
