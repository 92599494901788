import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Cookies from "js-cookie";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import navTop from "@/components/navTop"
import swiper1 from "@/components/swiper"
import swiper2 from "@/components/swiper2"
import footerNav from "@/components/footer"
import carousel from "@/components/carousel"

Vue.component("nav-top", navTop)

Vue.component("swiper-1", swiper1)
Vue.component("swiper-2", swiper2)
Vue.component("footer-nav", footerNav)
Vue.component("carousel", carousel)

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

var zh=localStorage.getItem("zh")||'zh-CN'

if(zh=='zh-CN'){
  Cookies.set("think_var", "cn", {})
}
if(zh=='en-US'){
  Cookies.set("think_var", "en", {})
}
if(zh=='jp-JP'){
  Cookies.set("think_var", "jp", {})
}

const i18n = new VueI18n({
  locale: zh, // 语言标识
  // this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./assets/lang/zh'), // 中文语言包
    'en-US': require('./assets/lang/en'),// 英文语言包
    'jp-JP': require('./assets/lang/jp') // 日文语言包
  }
})

Vue.use(ElementUI);

Vue.config.productionTip = false



import * as API from '@/api';


new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this
    Vue.prototype.$API = API;
    Vue.prototype.$baseUrl= "https://www.ravencraft.com.cn";
  },
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
