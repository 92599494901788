import requests from './request';


//新品1
export const getHot1 = (data) => requests({ url: `/api/pole/hotList`, data: data, method: "post" })
//新品2
export const getHot2 = (data) => requests({ url: `/api/decoy/hotList`, data: data, method: "post" })
//新品3
export const getHot3 = (data) => requests({ url: `/api/part/hotList`, data: data, method: "post" })
//获取顶部轮播
export const getSliderIndex = (data) => requests({ url: `/api/slide/index`, data, method: "post" })
//获取底部轮播
export const getSliderFooter = () => requests({ url: `/api/slide/foot`, method: "GET" })
//获取新闻列表
export const getNews = (data) => requests({ url: `/api/news/index`, data: data, method: "post" })
//获取新闻详情
export const getNewsDetail = (data) => requests({ url: `/api/news/detail`, data: data, method: "post" })
//获取钓手列表
export const getManList = (data) => requests({ url: `/api/man_list/index`, data: data, method: "post" })
//获取钓手详情
export const getManDetail = (data) => requests({ url: `/api/man_list/detail`, data: data, method: "post" })
//获取钓竿分类列表
export const getPoleList = (data) => requests({ url: `/api/pole/index`, data: data, method: "post" })
//获取钓竿详情
export const getPoleDetail = (data) => requests({ url: `/api/pole/detail`, data: data, method: "post" })
//获取钓竿型号详情
export const getPoleSpec = (data) => requests({ url: `/api/pole/spec`, data: data, method: "post" })


//获取拟饵分类列表
export const getDecoyList = (data) => requests({ url: `/api/decoy/index`, data: data, method: "post" })
//获取拟饵详情
export const getDecoyDetail = (data) => requests({ url: `/api/decoy/spec`, data: data, method: "post" })
//获取配件分类列表
export const getPartList = (data) => requests({ url: `/api/part/index`, data: data, method: "post" })
//获取配件详情
export const getPartDetail = (data) => requests({ url: `/api/part/spec`, data: data, method: "post" })


//全局搜索
export const getSearchList = (data) => requests({ url: `/api/index/searchAll`, data: data, method: "post" })
//合作伙伴
export const getPartner = (data) => requests({ url: `/api/distributor/index`, data: data, method: "post" })
//合作伙伴
export const getAddress = (data) => requests({ url: `/api/distributor/getAreaList`, data: data, method: "post" })



//获取首页分类图
export const getCateImgs = (data) => requests({ url: `/api/index/getCategoryimglist`, data: data, method: "post" })
//获取底部图标
export const getIconList = (data) => requests({ url: `/api/index/getIconList`, data: data, method: "post" })
//获取介绍
export const getContent = (data) => requests({ url: `/api/index/getContent`, data: data, method: "post" })



